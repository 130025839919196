import { FaLinkedin, FaInstagram, FaSteam, FaArtstation, FaGithub } from 'react-icons/fa';
import {
    ecommerce,
    hacktivoverflow,
    kanban,
    miniwordpress,
    navTransition,
    taxiOnline,
    trivy,
    logo_firebase,
    logo_gcp,
    logo_nodejs,
    logo_rn,
    logo_vuejs
} from '../themes/images';


const experiences = [
    {
        id: 0,
        startYear: 2021,
        companyName: 'eFishery',
        position: 'Frontend Engineer (React.js)',
        description: 'Maintain and develop trusted web application (TWA), collaborating with backend engineer using GraphQL client',
        yearsOfService: 'Sep 2021 - Now'
    },
    {
        id: 1,
        startYear: 2020,
        companyName: 'PT. Code Development Indonesia',
        position: 'Front-End Developer (Mobile - React Native)',
        description: 'Maintain and develop hybrid mobile app application using React Native (a Javascript library to create mobile application UI). Collaborating with Back-End Developer to integrate API through Redux as a React Native state management/ single source of truth.',
        yearsOfService: 'Oct 2020 - Sep 2021'
    },
    {
        id: 2,
        startYear: 2019,
        companyName: 'PT. Axiata Digital SI (Boost)',
        position: 'Front-End Developer (Mobile - React Native)',
        description: 'Maintain and develop hybrid mobile app application using React Native (a Javascript library to create mobile application UI). Collaborating with Back-End Developer to integrate API through Redux as a React Native state management/ single source of truth.',
        yearsOfService: 'Oct 2019 - Sep 2021'
    },
    // {
    //     id: 2,
    //     startYear: 2018,
    //     companyName: 'PT. Astra Agro Lestari, Tbk.',
    //     position: 'Plantation Management Development Program (PMDP)',
    //     description: 'As a Management Trainee, I do Transportation audit for the new project implementation in PT Astra Agro Lestari, Tbk. to make sure how the transportation from the palm oil afdelling field to the Palm Oil Factory is work as expected. Beside Transportation, I also get a task for Crop audit for a little while.',
    //     yearsOfService: 'Jul 2018 - Dec 2018'
    // },
    // {
    //     id: 3,
    //     startYear: 2016,
    //     companyName: 'PT. Soyuren Indonesia',
    //     position: 'Internship Supervisor',
    //     description: 'As an internship supervisor, my job is to oversee the ongoing project at Nguwot Bridge Construction, from the excavation work, foundation, retaining wall, until the bridge erection itself.',
    //     yearsOfService: 'Jun 2016 - Aug 2016'
    // }
];

const webProjects = [
    {
        id: 1,
        title: 'E-Commerce',
        webName: 'Cubes',
        image: ecommerce,
        description: 'A simple e-commerce website which simulate CRUD process for product. Also user can add product to cart, increase and decrese their prodcuts in cart',
        url: 'https://github.com/tyogautomo/e-commerce-1',
        buttonTitle: 'Github',
        tech: [
            {
                name: 'VueJS',
                logo: logo_vuejs
            },
            {
                name: 'GCP',
                logo: logo_gcp
            },
            {
                name: 'node.js',
                logo: logo_nodejs
            }
        ]
    },
    {
        id: 2,
        title: 'Hacktiv-overflow',
        webName: 'Questo',
        image: hacktivoverflow,
        description: 'A webiste to mockup the stackoverflow feature, like ask a question, answer it, upvote and downvote. Also contains a feature to watch some category',
        url: 'https://github.com/tyogautomo/hacktivoverflow-1',
        buttonTitle: 'Github',
        tech: [
            {
                name: 'VueJS',
                logo: logo_vuejs
            },
            {
                name: 'GCP',
                logo: logo_gcp
            },
            {
                name: 'node.js',
                logo: logo_nodejs
            }
        ]
    },
    {
        id: 3,
        title: 'Kanban',
        webName: 'Kanbanana',
        image: kanban,
        description: 'Backlog app (kanban) to manage project tasks, user can drag and drop the task card to BACKLOG, TO DO, DOING, and DONE',
        url: 'https://kanbanana-c2410.firebaseapp.com/',
        buttonTitle: 'Website',
        tech: [
            {
                name: 'VueJS',
                logo: logo_vuejs
            },
            {
                name: 'Firebase',
                logo: logo_firebase
            }
        ]
    },
    {
        id: 4,
        title: 'Mini-Wordpress',
        webName: 'Minipress',
        image: miniwordpress,
        description: 'A website to mockup the wordpress feature to create an article/ blog. User can make their articles private/draft or make it published so other user can find them in discover feature',
        url: 'https://github.com/tyogautomo/mini-wp-1',
        buttonTitle: 'Github',
        tech: [
            {
                name: 'VueJS',
                logo: logo_vuejs
            },
            {
                name: 'GCP',
                logo: logo_gcp
            },
            {
                name: 'node.js',
                logo: logo_nodejs
            }
        ]
    }
];

const mobileProject = [
    {
        id: 1,
        title: 'Realtime Chat App',
        image: navTransition,
        description: 'A simple chat app mocking Whatsapp, built with React Native, Express.js, and Sokcket.io as a realtime protocol.',
        url: 'https://github.com/tyogautomo/realtime-private-chat',
        buttonTitle: 'Github',
        tech: [
            {
                name: 'React Native',
                logo: logo_rn
            }
        ]
    },
    {
        id: 2,
        title: 'Taxi Online Order',
        image: taxiOnline,
        description: 'A demo app to mock up the Grab mobile app to order a driver via online. User can choose a pick up point, destination point, and finally show them the route to go there via Google Map',
        url: 'https://github.com/tyogautomo/online-taxi-app',
        buttonTitle: 'Github',
        tech: [
            {
                name: 'React Native',
                logo: logo_rn
            },
            {
                name: 'GCP',
                logo: logo_gcp
            }
        ]
    },
    {
        id: 3,
        title: 'Trivy',
        image: trivy,
        description: 'Simple trivia quiz app made using react native and opentdb API. Currently the app cant load because I didnt extend my VM service on GCP',
        url: 'https://play.google.com/store/apps/details?id=com.yoga.trivy.client&hl=en',
        buttonTitle: 'Google Play',
        tech: [
            {
                name: 'React Native',
                logo: logo_rn
            },
            {
                name: 'GCP',
                logo: logo_gcp
            },
            {
                name: 'node.js',
                logo: logo_nodejs
            }
        ]
    }
];

const socialMedia = [
    {
        name: 'Linkedin',
        url: 'https://www.linkedin.com/in/tyogautomo/',
        icon: FaLinkedin
    },
    {
        name: 'Instagram',
        url: 'https://www.instagram.com/tyogautomo/',
        icon: FaInstagram
    },
    {
        name: 'Steam',
        url: 'https://steamcommunity.com/id/tyogautomo/',
        icon: FaSteam
    },
    {
        name: 'Artstation',
        url: 'https://www.artstation.com/tyogautomo',
        icon: FaArtstation
    },
    {
        name: 'Github',
        url: 'https://www.github.com/tyogautomo',
        icon: FaGithub
    }
];

const email = 'yoga.utomo27@gmail.com'


export {
    experiences,
    webProjects,
    mobileProject,
    socialMedia,
    email
};
