import React from 'react';

import styles from './ExperienceCard.module.css';

function ExperienceCard({ exp }) {
    return (
        <div className={styles.experienceContainer}>
            <div className={styles.yearContainer}>
                <p>{exp.startYear}</p>
            </div>
            <div className={styles.timelineSymbolContainer}>
                <div className={styles.pole}>
                    <div className={styles.innerPole}></div>
                </div>
                <div className={styles.verticalLineSection}>
                    <div className={styles.verticalLine}></div>
                </div>
            </div>
            <div className={styles.jobContainer}>
                <p>{exp.companyName}</p>
                <p>{exp.position}</p>
                <p>{exp.description}</p>
                <p><span>years of service, </span><span>{exp.yearsOfService}</span></p>
            </div>
        </div>
    );
};

export { ExperienceCard };
