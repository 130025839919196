import React from 'react';

import styles from './Experience.module.css';
import { ExperienceCard } from '../../components/ExperienceCard/ExperienceCard.component';
import { experiences } from '../../resource_data/dummyData';

function Experience() {
    return (
        <>
            <h1 className={styles.introTitle}>Journey</h1>
            <div className={styles.experiencesContainer}>
                <p>Here are some of my last experiences...</p>
                {
                    experiences.map((exp, i) => <ExperienceCard key={i} exp={exp} />)
                }
                <p className={styles.check}>
                    Check my <a href="https://www.linkedin.com/in/tyogautomo/" target="_blank" rel="noopener noreferrer">LinkedIn</a> profile for more of my experiences and organizations ...
                </p>
            </div>
        </>
    )
}

export { Experience };
