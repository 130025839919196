import React from 'react';
import { MdEmail } from 'react-icons/md';

import styles from './Footer.module.css';
import { socialMedia, email } from '../../resource_data/dummyData';

function Footer() {
    const renderIcon = (media, i) => {
        const TagName = media.icon;
        return (
            <a
                key={i}
                href={media.url}
                className={styles.iconSocial}
                target="_blank"
                rel="noopener noreferrer"
            >
                <TagName />
            </a>
        )
    }

    const renderSocialMedia = () => {
        return (
            <>
                <p>Connect me on another social media.</p>
                <div className={styles.socialMediaContainer}>
                    {socialMedia.map((media, i) => renderIcon(media, i))}
                </div>
            </>
        )

    };

    const renderEmail = () => {
        return (
            <>
                <p className={styles.textEmail}>Or contact me via Email</p>
                <a href={`mailto:${email}?subject=From Personal Website`} className={styles.emailButton}>
                    <MdEmail className={styles.iconMail} />
                    email
               </a>
            </>
        )
    };

    return (
        <div>
            <div className={styles.footerContainer}>
                {renderSocialMedia()}
                {renderEmail()}
            </div>
            <div className={styles.footerBottom}>
                <p>© Copyright 2020</p>
                <p>Made with ❤ in Indonesia. Design by Yoga Utomo</p>
            </div>
        </div>
    );
}

export { Footer };
