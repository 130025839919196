import React from 'react';

import styles from './PortfolioCard.module.css';

function PortfolioCard({ totalItems, project }) {
    const renderTechLogo = (techs) => {
        return (
            <>
                {techs.map((tech, i) => (
                    <div
                        key={i}
                        style={{
                            backgroundImage: `url(${tech.logo})`
                        }}
                        title={tech.name}
                        className={styles.logo}
                    />
                ))}
            </>
        )
    };

    return (
        <div
            className={`${styles.portfolioCardContainer} ${totalItems < 4 && styles.horMargin}`}
            style={{
                backgroundImage: `url(${project.image})`
            }}
        >
            <div className={styles.blackLayer}>
                <p>{project.description}</p>
                <div className={styles.techContainer}>
                    <p>Tech's: </p>
                    {renderTechLogo(project.tech)}
                </div>
                <a href={project.url} target="_blank" rel="noopener noreferrer">
                    See on {project.buttonTitle}
                </a>
            </div>
            <p className={styles.titleCard}>{project.webName || project.title}</p>
        </div>
    );
}

export { PortfolioCard };
