import React from 'react';

import styles from './Home.module.css';
import { Banner } from '../../components/Banner/Banner.component';
import { HorizontalSeparator } from '../../components/HorizontalSeparator/HorizontalSeparator.component';
import { Intro } from '../../components/Intro/Intro.component';
import { Experience } from '../../components/Experience/Experience.component';
import { Portfolio } from '../../components/Portfolio/Portfolio.component';
import { Footer } from '../../components/Footer/Footer.component';

function Home() {
    return (
        <div>
            <Banner />
            <div className={styles.contentBackground} >
                <div className={styles.contentContainer}>
                    <HorizontalSeparator />
                    <Intro />
                    <HorizontalSeparator />
                    <Portfolio />
                    <HorizontalSeparator />
                    <Experience />
                </div>
            </div>
            <Footer />
        </div>
    )
};

export { Home };
