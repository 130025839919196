import React from 'react';

import styles from './Portfolio.module.css';
import { PortfolioCard } from '../PortfolioCard/PortfolioCard.component';
import { webProjects, mobileProject } from '../../resource_data/dummyData';

function Portfolio() {
    return (
        <>
            <h1 className={styles.introTitle}>Portfolio</h1>
            <div className={styles.portfolioContainer}>
                <p>Some of them are from bootcamp's personal project, team project, or just a tech exploration...</p>
                <p>Website</p>
                <div className={styles.websiteProjectContainer}>
                    {webProjects.map((project, i) => <PortfolioCard key={i} project={project} />)}
                </div>
                <p>Mobile</p>
                <div className={styles.mobileProjectContainer}>
                    {mobileProject.map((project, i) => <PortfolioCard key={i} project={project} totalItems={3} />)}
                </div>
            </div>
        </>
    )
}

export { Portfolio };
