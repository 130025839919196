import React from 'react';

import styles from './HorizontalSeparator.module.css';

function HorizontalSeparator() {
    return (
        <div className={styles.separatorContainer}>
            <div className={styles.lineSection}>
                <div className={styles.line}></div>
            </div>
            <div className={styles.middleSection}>
                <div className={styles.icon}></div>
            </div>
            <div className={styles.lineSection}>
                <div className={styles.line}></div>
            </div>
        </div>
    )
};

export { HorizontalSeparator };
