import React from 'react';

import styles from './Intro.module.css';
import avatar from '../../assets/images/avatar.png';

function Intro() {

    const yearsDifference = (graduateDate) => {
        const now = new Date();
        const graduate = new Date(graduateDate);
        let months = (now.getFullYear() - graduate.getFullYear()) * 12;
        months -= graduate.getMonth() + 1;
        months += now.getMonth() + 1;

        const years = Math.floor(months / 12);
        const restMonths = months % 12;

        return years === 0 ?
            `${restMonths} month(s)` :
            months === 0 ?
                `${years} year(s)` :
                `${years} years and ${restMonths} months`
    };

    return (
        <>
            <h1 className={styles.introTitle}>Prologue</h1>
            <div className={styles.descriptionContainer}>
                <div className={styles.avatarSection}>
                    <img src={avatar} alt="avatar" />
                </div>
                <p>
                    Hello everyone, I'm <span>Try Yoga Utomo</span>, you can call me <span>Yoga</span> as well.
                    I graduated as a Civil Engineer in 2017 and highly passionate in Computer and Technology.
                    In 2019, I decided to change my whole career and take the best Coding Bootcamp in Indonesia, to learn Full-Stack Javascript Program.
                    Since I graduated from Coding Bootcamp, i had <span>{yearsDifference('2019-09-27')}</span> experience being a Software Engineer, especially Front End Developer, and it was really amazing.
                    Besides programming, Im also into arts, from traditional arts until modern digital arts. These passions
                    bring me into a Front-End development and Game Development.
                </p>
            </div>
        </>
    );
}

export { Intro };
