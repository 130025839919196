import React, { useState, useEffect } from 'react';

import styles from './Banner.module.css';
import layer1 from '../../assets/images/layer_1.png';
import layer2 from '../../assets/images/layer_2.png';
import layer3 from '../../assets/images/layer_3.png';
import layer4 from '../../assets/images/layer_4.png';
import layer5 from '../../assets/images/layer_5.png';
import layer6 from '../../assets/images/layer_6.png';
import layer7 from '../../assets/images/layer_7.png';
import layer8 from '../../assets/images/layer_8.png';

function Banner() {
    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
        document.title = "Yoga Utomo"
        window.addEventListener('scroll', onScrollPage)
    });

    const onScrollPage = () => {
        setScrollY(window.scrollY);
    };

    const renderBehindLayer = (img, multiplier, alt, scrolled) => {
        return (
            <img
                src={img} className={`${styles.banner} ${styles.bannerFixed}`}
                alt={alt}
                style={scrolled ? { transform: `translateY(${-scrollY / multiplier}px)` } : null}
            />
        );
    };

    const renderBannerTitle = () => {
        return (
            <div
                className={`${styles.bannerTitle} ${styles.bannerFixed}`}
                style={{ transform: `translateY(${-scrollY / 4}px)`, }}
            >
                <p>HELLO</p>
                <p>WORLD!</p>
            </div>
        );
    };

    return (
        <div>
            {renderBehindLayer(layer8, null, 'layer8', false)}
            {renderBehindLayer(layer7, 15.5, 'layer7', true)}
            {renderBehindLayer(layer6, 5.5, 'layer6', true)}
            {renderBehindLayer(layer5, 4, 'layer5', true)}
            {renderBehindLayer(layer4, 3, 'layer4', true)}
            {renderBehindLayer(layer3, 2, 'layer3', true)}
            {renderBannerTitle()}
            {renderBehindLayer(layer2, 1.5, 'layer2', true)}
            <img
                src={layer1}
                className={`${styles.banner}`}
                style={{ position: 'relative', imageRendering: 'pixelated' }} alt="layer1"
                onDrag={e => e.preventDefault()}
            />
            <div
                className={styles.frontBannerContainer}
                style={{ transform: `translateY(${-scrollY}px)` }}
            >
                <div className={styles.firefly1} />
                <div className={styles.firefly2} />
            </div>
        </div>
    )
}

export { Banner };
